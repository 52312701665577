<template>
  <div>
    <div class="form-row mt-2">
      <div class="col-md-6 mb-2">
        Trung tâm Test đầu vào <span class="text-danger">(*)</span>
        <ValidationProvider vid="center_test_input_id" name="Trung tâm Test đầu vào" :rules="{required:rules}"
                            v-slot="{ errors,classes }">
          <el-select v-model="testInput.center_id"
                     filterable class="w-100"
                     placeholder="Chọn trung tâm"
                     @change="handleCenterChange()"
                     :disabled="disableInput"
          >
            <el-option
                v-for="item in centersTestInput"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :class="classes"
            >
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select >
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
              {{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-md-6" >
        Cơ sở Test đầu vào <span class="text-danger">(*)</span>
        <ValidationProvider vid="branch_id" name="Cơ sở Test đầu vào" :rules="{required:rules}"
                            v-slot="{ errors,classes }">
          <el-select v-model="testInput.branch_id" filterable class="w-100" placeholder="Chọn cơ sở"
                     @change="changeBranchTestInput()" :disabled="disableInput" :loading="loadingBranch">
            <el-option
                v-for="item in branchesTestInput"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :class="classes"
            >
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
              {{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-6 mb-2">
        Hình thức Test đầu vào<span class="text-danger">(*)</span>
        <ValidationProvider vid="center_id" name="Hình thức test đầu vào" :rules="{required:rules}"
                            v-slot="{ errors,classes }">
          <el-select v-model="testInput.hinh_thuc_test_dau_vao"
                     filterable class="w-100"
                     placeholder="Chọn hình thức"
                     @change="changeHinhThucTestInput()"
                     :disabled="disableTypeTest">
            <el-option
                v-for="item in hinhThucTestDauVao"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :class="classes"
            >
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
              {{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-md-6 mb-2">
        Thời gian test đầu vào<span class="text-danger">(*)</span>
        <ValidationProvider vid="date_test_input" name="Ngày" :rules="{required:rules}" v-slot="{ errors,classes }">
          <el-date-picker
              v-model="testInput.ngay_hen"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :arrow-control="true"
              :picker-options="pickerOptions"
              @change="changeDataTestInput()"
              :clearable="false"
              :class="classes"
              type="date"
              placeholder="Chọn ngày"
              :disabled="disableInput"
          >
          </el-date-picker>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
              {{ errors[0] }}
            </div>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-md-6 mb-2">
        Giờ test đầu vào<span class="text-danger">(*)</span>
        <ValidationProvider vid="time_test_input" name="Ca" :rules="{required:rules}" v-slot="{ errors }">
          <el-select filterable class="w-100" v-model="testInput.time" placeholder="Chọn ca" :disabled="disableTime"  @change="changeHour()">
            <el-option-group
                v-for="group in  testHourList"
                :key="group.label"
                :label="group.label"
            >
              <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :disabled=" isDisableHour(item.label)"
                  :value="item">
                <span style="float: left">{{ item.label }}</span>
                <span v-if="isHourTooBad(item.label)"
                      style="float: right; color: #c5a862; font-size: 13px">Nhiều KH lên test ca này!</span>
              </el-option>
            </el-option-group>
          </el-select>
          <div class="fv-plugins-message-container">
            <div data-field="name" data-validator="notEmpty" class="fv-help-block">
              {{ errors[0] }}
            </div>
          </div>
          <p v-if=" testInput.time && isHourTooBad(testInput.time.value)"
             style="color: #c5a862">
            Khách hàng có thể phải đợi do có nhiều lượt đăng ký ca này!
          </p>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRANCH_LIST,
  CENTER_LIST,
  GET_INPUT_SCHEDULE,
  GET_TESTERT
} from "@/core/services/store/customer/customer.module";
import {TIME_SELECT_OPTION} from "@/core/config/customer/selectTimeOption";
import moment from 'moment-timezone';
import {ONLINE, TYPE_EXAMS} from "@/core/config/testInput/testInputOption";
import {Cambridge_Mentor_ID, IELTS, OFFLINE, TYPES} from "../../../core/config/testInput/testInputOption";
import {CHECK_SLOT_BY_DAY} from "@/core/services/store/customer/testInput.module";
import {TOEIC} from "../../../core/config/center/centerOption";
import {TIME_SELECT_OPTION_ONLINE} from "../../../core/config/customer/selectTimeOption";

export default {
  name: "reschedule-test-input",
  props: {
    checkExist: {
      default: false
    },
    testInputSchedule: {
      default: Object
    },
    checkDisable: {
      default: false
    },
    showTestInput: {
      default: true
    }
  },

  data() {
    return {
      optionSelectExams: TYPE_EXAMS,
      optionSelectTime: TIME_SELECT_OPTION,
      optionSelectTimeOnline: TIME_SELECT_OPTION_ONLINE,
      centersTestInput: [],
      branchesTestInput: [],
      hinhThucTestDauVao: TYPES,
      OFFLINE: OFFLINE,
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      testInput: {
        center_id: '',
        branch_id: '',
        hinh_thuc_test_dau_vao: '',
        ngay_hen: '',
        time: null,
        type_exams: IELTS
      },
      testers: {},
      input_schedule: {},
      dataDisable: {},
      allSlots: {},
      loadingBranch: false
    }
  },
  computed: {
    rules() {
      if (this.checkDisable || !this.showTestInput || this.checkExist  ) {
        return false;
      }
      return  true;
    },
    disableTime() {
     return false
    },
    disableInput() {
      return false;
    },
    disableTypeTest(){
      return this.testInput.center_id === Cambridge_Mentor_ID
    },
    isTypeOffline() {
      if (this.testInput.hinh_thuc_test_dau_vao === OFFLINE) {
        return true;
      }
      return false;
    },
    testHourList(){
      return  this.optionSelectTimeOnline
    }
  },
  methods: {
    error(mess) {
      this.$alert(mess, 'Thông báo', {
        confirmButtonText: 'OK',

      });
    },
    checkSlotByDay() {
      if ((this.testInput.hinh_thuc_test_dau_vao != undefined && this.testInput.ngay_hen != undefined && this.testInput.branch_id != undefined)) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        ;

        let payload = {
          branch_id: this.testInput.branch_id,
          day: moment(this.testInput.ngay_hen).format('YYYY-MM-DD'),
          type: this.testInput.hinh_thuc_test_dau_vao
        }
        this.$store.dispatch(CHECK_SLOT_BY_DAY, payload).then((data) => {
          let Hour = {...data.data}
          Object.keys(data.data).forEach((key,index)=>{
            Hour[key] = Hour[key]?.type??0
          })
          const isOverTime = moment(this.testInput.ngay_hen).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') || (moment(this.testInput.ngay_hen).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') && moment().format('HH:mm') > '20:00') ;
          this.allSlots = Hour
          let valueHour = Object.values(Hour)
          if (valueHour.filter((e) => e == 0).length == valueHour.length || isOverTime) {
            this.allSlots = {}
            this.error(`${this.testInput.hinh_thuc_test_dau_vao==OFFLINE?'Không có admin test trực tại điểm.':'Ngày chọn đã hết ca trống. Vui lòng chọn ngày khác hoặc liên hệ hotline 0974602675 để đề xuất thêm ca test.'}`);
          }
          loading.close()

        }).catch((res) => {
          loading.close()
          this.error(`${this.testInput.hinh_thuc_test_dau_vao==OFFLINE?'Không có admin test trực tại điểm.':'Ngày chọn đã hết ca trống. Vui lòng chọn ngày khác hoặc liên hệ hotline 0974602675 để đề xuất thêm ca test.'}`);
          this.allSlots = {}
        }).finally(() => {
          if (this.checkExist) {
            // this.allSlots.this.testInput.time = 1
          }
        })
      }

    },
    isDisableHour(hour) {
      if (!this.allSlots || Object.keys(this.allSlots).length == 0) {
        return true;
      }
      let stringHour = `${this.testInput.ngay_hen} ${hour}`
      return new Date(stringHour).getTime() < new Date().getTime() || (this.allSlots[hour] == 0) || ((this.allSlots[hour] == 2) && this.testInput.hinh_thuc_test_dau_vao == ONLINE)
    },
    isHourTooBad(hour) {
      let stringHour = `${this.testInput.ngay_hen} ${hour}`
      return new Date(stringHour).getTime() > new Date().getTime() && (this.allSlots[hour] == 2) && (this.testInput.hinh_thuc_test_dau_vao == OFFLINE)
    },
    disabledDate(date) {
      let tempDate = new Date();
      let currentDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0);
      let nextWeek = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate() + (+7 - (tempDate.getDay() == 0 ?7:+tempDate.getDay())));
      return date < currentDate | date > nextWeek;
    },
    async handleCenterChange() {
      this.testInput.branch_id = '';
      await this.getBranches();
      this.testInput.time = null
      this.$emit('infoTestInput', this.testInput);
      this.changeTime();
      if(this.testInput.center_id === Cambridge_Mentor_ID){
        this.testInput.hinh_thuc_test_dau_vao = OFFLINE;
      }
    },
    getCenters() {
      this.$store.dispatch(CENTER_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.centers = response.data;
              this.centersTestInput = response.data.filter(center => center.is_edutalk === 1 && center.id !== TOEIC);
            }
          });
    },
    async getBranches() {
      this.loadingBranch = true ;
      await this.$store.dispatch(BRANCH_LIST, {center_id: this.testInput.center_id})
          .then((response) => {
            if (response.status === 422) {
              this.$notify({
                title: 'Warning',
                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                type: 'warning'
              });
            } else {
              this.branchesTestInput = response.data
            }
          }).finally(()=>{
            this.loadingBranch = false;
          });
    },
    changeHour(){
      this.$emit('infoTestInput', this.testInput);
    },
    changeDataTestInput() {
      this.testInput.time = null
      this.$emit('infoTestInput', this.testInput);
      this.changeTime();
      this.checkSlotByDay()
    },
    changeBranchTestInput() {
      this.testInput.time = null
      this.$emit('infoTestInput', this.testInput);
      this.changeTime();
      this.testInput = {...this.testInput,ngay_hen: null}
    },
    changeHinhThucTestInput() {
      this.testInput.time = null
      this.$emit('infoTestInput', this.testInput);
      this.changeTime();
      this.testInput = {...this.testInput,ngay_hen: null}
    },
    dataTestInputSchedule() {
      this.testInput.center_id = this.testInputSchedule.center_id
      this.testInput.branch_id = this.testInputSchedule.branch_id
      this.testInput.hinh_thuc_test_dau_vao = this.testInputSchedule.type
      this.testInput.ngay_hen = moment(this.testInputSchedule.schedule).format('YYYY-MM-DD')
      this.testInput.time = moment(this.testInputSchedule.schedule).format('HH:mm')
      this.getBranches()
      this.$emit('infoTestInput', this.testInput)
    },

    checkDisableTime(value) {
      return value < moment().format("HH:mm");
    },
    changeTime() {
      this.$store.dispatch(GET_TESTERT, {
        time: this.testInput.ngay_hen,
        branch_id: this.testInput.branch_id,
        hinh_thuc_test: this.testInput.hinh_thuc_test_dau_vao
      }).then((response) => {
        this.testers = response;
        this.getInputSchedule();
      });
    },
    getInputSchedule() {
      this.$store.dispatch(GET_INPUT_SCHEDULE, {
        time: this.testInput.ngay_hen,
        branch_id: this.testInput.branch_id,
        hinh_thuc_test: this.testInput.hinh_thuc_test_dau_vao
      }).then((response) => {
        this.input_schedule = response;
        this.checkDisableTimeOption(response)
      });
    },
    checkDisableTimeOption(response) {
      let arrayDisable = [];
      let arr = [];
      if (Object.keys(this.testers).length) {
        Object.values(response).map(function (val, key) {
          TIME_SELECT_OPTION.map(function (value) {
            key = key + 1;
            if (value.live_time == key) {
              Object.values(value.options).map(function (option, keyOp) {
                if (response[key] && !response[key][option.value]) {
                  return true;
                } else {
                  if (!response[key]) {
                    return false;
                  }
                  Object.values(response[key][option.value]).map(function (res, keyRes) {
                    Object.values(value.options).map(function (op, kp) {
                      if (res.end_time > op.value && res.start_time <= op.value) {
                        arrayDisable.push(op.value);
                      }
                    })
                  })
                  arr[key] = arrayDisable;
                }
              })
            }
          });
        });
        this.dataDisable = arr;
        this.test();
      }
    },
    test() {
      for (const val of [1, 2, 3]) {
        if (!this.testers[val]) {
          return false;
        }
        let live = this.testers[val].length;
        if (this.dataDisable[val]) {
          for (const num of this.dataDisable[val]) {
            TIME_SELECT_OPTION.map(function (time) {
              if (time.live_time = live) {
                Object.values(time.options).map(function (op) {
                  if (num == op.value) {
                    op.live_time++;
                    if (live <= op.live_time) {
                      op.live_time = live;
                    }
                  }
                })
              }
            })
          }
          ;
        }
      }
    },
    countTesters(arr, live_time) {
      if (!Object.keys(this.testers).length) {
        return false;
      }
      let a = 0
      if (this.testers[live_time]) {
        a = this.testers[live_time].length;
      }
      return arr.live_time >= a;
    }
  },
  async mounted() {
    console.log('tt')
    this.getCenters();
    if (this.checkExist) {
      this.dataTestInputSchedule()
      this.checkSlotByDay()
      console.log(1111111)
    } else {
      this.testInput = {}
      this.testInput.hinh_thuc_test_dau_vao = ''
    }
  },
  watch: {
    checkExist(newValue) {
      if (!newValue) {
        this.testInput = {}
        this.testInput.hinh_thuc_test_dau_vao = ''
        document.querySelectorAll(".fv-help-block").forEach(el => el.remove());
      } else {
        this.dataTestInputSchedule()
      }
    }
  }
}
</script>

<style scoped>

</style>