<script>
export default {
  name: "CommentAndConclusion",
  props:{
    comment:{
      type:Object
    }
  }
}
</script>
<template>
  <div>
    <header class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="circle" style="position: relative">
          <img  alt=""  src="../../../../../public/media/img-child-test-input/OtherComment.png" style="position: absolute;top: 6px;left: 5px">
        </div>
        <p style="font-size: 13px;font-weight: bold;margin-left: 6px">
          {{comment.name}}
        </p>
      </div>
      <div class="d-flex" v-if="comment?.start">
        <span>Evaluation (Đánh giá):</span>
        <el-rate :value="comment.start" :disabled="true"></el-rate>
      </div>
    </header>
    <div class="card"></div>
    <div class="mt-3" v-for="(item,index) in comment.listComment" :key="index">
      <p style="color: #545454;;font-weight: bold">{{item.title}}:</p>
      <p style="white-space: pre-wrap">{{item.comment !== ''?item.comment:'Không có'}}</p>
    </div>
  </div>
</template>
<style scoped>
.circle {
  width: 20px;
  height: 20px;
  background-color: #FFD602;
  border-radius: 50%; /* Điều này làm cho div trở thành hình tròn */
}
</style>