<template>
  <div class="bg-white rounded-sm px-4 pt-4 pb-2 mb-3">
    <div class="row ">
      <div class="col-6 d-flex  align-content-center">
        <img src="../../../../assets/img/loaImg.png"  style="height: 25px ">
        <h4 class="ml-2 mb-0 mt-1" style="font-size: 16px">{{ resultTest?.nameType }}</h4>
      </div>
      <div class="col-6 score d-flex flex-row-reverse">
        <h4 style="font-size: 16px">Số điểm: {{ resultTest?.totalPoint ?? resultTest?.totalPointAuto }} /{{ pointLadder }}</h4>
      </div>
    </div>
    <div class="mt-3">
      <h4>Nhận xét:</h4>
      <p v-if="resultTest.totalQuestions" class="mb-0">Học viên đúng
        {{ resultTest.correctQuestions }}/{{ resultTest.totalQuestions }} câu trong kỹ năng này</p>
      <div v-else>
        <p class="mb-0" v-if="resultTest?.typePlagiarism !== 4">Học viên đọc phần nhận xét chi tiết để hiểu rõ những tiêu chí của kĩ năng này</p>
        <div class="text-center mt-2" v-if="resultTest?.typePlagiarism !== 4">
          <div class="demo-collapse">
            <el-collapse>
              <el-collapse-item title="Xem Thêm" name="1" class="text-[15px]" v-if="!check0And1Point">
                <div v-for="(dataComment, index) in resultTest.dataComments" :key="index"
                     class="mb-5 text-left text-[15px]">
                  <h4 style="font-weight: bold">Tiêu chí {{ index + 1 }}: {{ dataComment.nameCriteria }}</h4>
                  <p style="white-space: pre-line">
                    {{ dataComment.autoComment }}
                  </p>
                  <h4>Chi tiết :</h4>
                  <p style="white-space: pre-line">
                    {{ dataComment.comment }}
                  </p>
                  <hr>
                </div>
              </el-collapse-item>
              <el-collapse-item title="Xem Thêm" name="1" class="text-[15px]" v-else >
                <div  class="mb-5 text-left text-[15px]">
                  <p style="white-space: pre-line">
                    {{resultTest?.dataComments[0].autoComment}}
                  </p>
                  <h4 v-if="resultTest?.dataComments[0].comment">Chi tiết :</h4>
                  <p style="white-space: pre-line">
                    {{ resultTest?.dataComments[0].comment }}
                  </p>
                  <hr>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div v-if="+resultTest.type === writing && this.resultTest?.typePlagiarism">
          <hr>
          <p style="color: #FF0000;font-weight: bold">Bài viết có dấu hiệu đạo văn!</p>
          <div class="row">
            <div class="col-3">
              <p  class=" comment">Tỉ lệ đạo văn: </p>
              <p  class=" comment">Phương án xử lý:</p>
              <p>Nhận xét, dẫn chứng:</p>
            </div>
            <div class="col-8" style="padding-left: 0 !important;">
              <p  class="comment">{{dataPlagiarism?.label}}</p>
              <p class="comment">{{dataPlagiarism?.problemSolution}}</p>
              <p style="white-space: pre-wrap">{{resultTest?.descriptionPlagiarism??''}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {toRoundingMark} from "@/views/pages/customer/models/result_test";
import {PLAGIARISM_RATE} from "../../../../core/config/testInput/testInputOption";

export default {
  name: "CommentsTest",
  props: {
    resultTest: {
      type: Object,
      default() {
        return {}
      }
    },
    testIELTS: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data(){
    return{
      writing: 3
    }
  },
  mounted() {

  },
  computed: {
    totalPointTest() {
      return this.testIELTS ? this.resultTest?.totalPoint ?? this.resultTest?.totalPointAuto : toRoundingMark(this.resultTest?.totalPoint ?? this.resultTest?.totalPointAuto * 10 / 9)
    },
    pointLadder() {
      return this.testIELTS ? "9.0" : "10"
    },
    check0And1Point() {
      return   [0,1].includes(+this.resultTest.dataComments[0].point)
    },
    dataPlagiarism(){
      if(this.resultTest?.typePlagiarism){
        return PLAGIARISM_RATE.find((e)=>e.value === +this.resultTest?.typePlagiarism)
      }
      return null
    }
  }
}
</script>

<style scoped>
@media (max-width: 700px) {
.comment{
  height: 60px;
}
}

/* Kiểu CSS cho màn hình từ 1024px trở lên */
@media (max-width: 1024px) {
  .comment{
   max-height: 60px;
  }
}
</style>