<template>
  <div class="bg-white rounded-sm px-4 pt-4 pb-2 mb-3">
    <div class="row ">
      <div class="col-6 d-flex  align-content-center">
        <img src="../../../../assets/img/loaImg.png" style="height: 25px ">
        <h4 class="ml-2 mb-0 mt-1" style="font-size: 16px">Overall</h4>
      </div>
      <div class="col-6 score  ">
        <h4 class="" style="font-size: 16px">Số điểm: {{pointOverall}} /{{pointLadder}}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OveralTest",
  props:{
    pointOverall:{
      type:Number,
      default(){
        return 0
      }
    },
    testIELTS:{
      type: Boolean,
      default(){
        return false
      }
    }
  },
  computed:{
    pointLadder(){
      return this.testIELTS?"9.0":"10"
    }
  }
}
</script>

<style scoped>
img {
  width: 20px;
}

.score {
  text-align: right;
}

h4 {
  font-size: 13px;
}
</style>